import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IFTContext } from "../../IFTContext";
import axios from "axios";
import QRCode from "react-qr-code";


export default function Stake({ }) {
  const {
    toastError, settings,
    getudata,
    dbuser, user_id,
    copyaddress,
    getuserDB,
    account,
    toastSuccess,
  } = useContext(IFTContext);
  const { link_ref_address } = useParams();

  const [amount, setamount] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [showqr, setshowqr] = useState(false);
  const [hash, sethash] = useState("");
  const [trx_address, settrx_address] = useState("");

  const [referral, setreferral] = useState("");

  const onDeposit = async () => {
    // sethash("");
    // if (!account) {  
    //   connectMetamask();
    //   toastError("Connect first");
    //   settrdone(false);
    //   return;
    // }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    if (!hash) {
      toastError("Enter Hash");
      settrdone(false);
      return;
    }
    if (!trx_address) {
      toastError("Enter Address");
      settrdone(false);
      return;
    }
    if (Number(amount) < 100) {
      toastError("Minimum 100 USDT required");
      settrdone(false);
      return;
    }
    // if (Number(amount) > balance) {
    //   toastError("Insufficient Balance");
    //   settrdone(false);
    //   // return;
    // }
    if (dbuser !== null && dbuser.restake) {
      if (Number(amount) < dbuser.last_stake) {
        toastError(`Minimum $${dbuser.last_stake} required for restake`);
        settrdone(false);
        return;
      }
    } else {
      var data = await getudata(referral);
      if (data.data.error) {
        toastError("Invalid Referral Address");
        settrdone(false);
        return "";
      }
    }
    settrdone(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "pool",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        u_id: user_id,
        hash: hash,
        address: trx_address,
        amount: Number(amount),
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        settrdone(false);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        getuserDB();
        toastSuccess(res.data.success);
      });

  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_id !== null) {
        setreferral(dbuser.ref_id);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [link_ref_address, dbuser, account]);
  // useEffect(() => {
  //   setchildamount(amount)
  // }, [amount])
  return (
    <>
      <div className="earn_crypto__cardone bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
        <h2 className="mb-3">Staking</h2>
        <div className="earn_crypto__rangeslider">
          <div className="slidecontainer">
            <div className="buy_crypto__formarea-group mb-5 mb-md-6">
              <label className="mb-2">Referral ID</label>
              <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                {dbuser !== null ? (
                  dbuser.ref_id !== null ? (
                    <input type="text" value={referral} />
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter Referral Address"
                      value={referral}
                      onChange={(e) => setreferral(e.target.value)}
                    />
                  )
                ) : (
                  <input
                    type="text"
                    placeholder="Enter Referral Address"
                    value={referral}
                    onChange={(e) => setreferral(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="buy_crypto__formarea-group mb-5 mb-md-6">
              <div className="d-flex justify-content-between">
                <label className="mb-2">Amount</label>
                {/* <label className="mb-2">Balance:{balance} USDT</label> */}
              </div>
              <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                <input
                  type="text"
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                />
              </div>
              <br />
              <button className="cmn-btn py-3 px-5 pt-2 px-md-6 d-block " onClick={() => setshowqr(!showqr)}>Pay here</button>
            </div>
            {showqr ?
              <div className="text-center p-5 p-md-6 br2 rounded-3 ">
                {settings !== null ?
                  <QRCode
                    size={256}
                    style={{ height: "150px" }}
                    value={`tron:${settings.tron_address}?amount=${amount}`}
                    // value={settings.tron_address}
                    viewBox={`0 0 256 256`}
                  /> : ''}
                <h6 className="fw_500 " onClick={() => copyaddress(settings !== null ? settings.tron_address : "")} title="Click to copy address">
                  TRC20: {settings !== null ? settings.tron_address : ""}{" "}
                  <i class="ti ti-clipboard-copy fs-five px-1 p4-color "></i>
                </h6>
              </div>
              : ''}
            <div className="buy_crypto__formarea-group mb-5 mb-md-6">
              <div className="d-flex justify-content-between">
                <label className="mb-2">Sender Address</label>
              </div>
              <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                <input
                  type="text"
                  value={trx_address}
                  onChange={(e) => settrx_address(e.target.value)}
                />
              </div>
            </div>
            <div className="buy_crypto__formarea-group mb-5 mb-md-6">
              <div className="d-flex justify-content-between">
                <label className="mb-2">Hash</label>
              </div>
              <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                <input
                  type="text"
                  value={hash}
                  onChange={(e) => sethash(e.target.value)}
                />
              </div>
            </div>

          </div>
        </div>

        {/* {hash ? (
          <div className="p-3 p4-color text-center">
            Hash :{" "}
            <a
              href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
              target="_blank"
              rel="noreferrer"
            >
              #: {formatAddress(hash)}
            </a>
          </div>
        ) : (
          ""
        )} */}
        {trdone ? (
          <div className="text-center">
            <img
              className="border-0"
              src="/assets/images/loader.gif"
              alt="loading"
              height={80}
              width={80}
            />
          </div>
        ) : (
          <button
            className="cmn-btn py-3 px-5 px-md-6 d-block w-100"
            onClick={() => onDeposit()}
          >
            Submit your Staking
          </button>
        )}
      </div>
    </>
  );
}
