import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";

export const IFTContext = createContext();

function IFTcontext() {
  const user_id = localStorage.getItem("conuid")
  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState(null);
  const [dbuser, setdbuser] = useState(null);
  // const [esigner, setesigner] = useState(null);
  const [DBbalance, setDBBalance] = useState(0);

  const formatAddress = (address, size = 6) => {
    if (address) {
      return address.substr(0, size) + "..." + address.substr(-size, size);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  async function disconnectNow() {
    try {
      // disconnect()
      localStorage.setItem("isconnected", false);
      localStorage.removeItem("conuid")
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      u_id: address ? address.toLowerCase() : address,
    });
  };
  const getuserDB = async (userid) => {
    var data = await getudata(userid);

    setdbuser(data.data.data);
    if (data.data.error) {
      return "";
    }
    var dap = data.data.data;
    var fbln =
      dap.staking_rew +
      dap.level_rew +
      dap.ror_rew -
      dap.t_withdrawal;
    setDBBalance(Number(fbln).toFixed(2));


  };
  useEffect(() => {
    getuserDB(user_id);
  }, [user_id]);
  useEffect(() => {
    getSetting();
  }, []);
  function openLevel(investAmount) {
    console.log("investAmount", investAmount);

    const levels = [1, 2, 3, 4];
    const investments = [200, 500, 1000, 2000];

    if (investAmount < investments[0]) {
      return { level: 0, amountNeeded: investments[0] - investAmount };
    }

    for (let i = 0; i < investments.length; i++) {
      if (investAmount < investments[i]) {
        return {
          level: levels[i - 1], // Current level
          amountNeeded: investments[i] - investAmount, // Amount needed for the next level
        };
      }
    }

    // If the investment is at or above the highest threshold
    return {
      level: levels[levels.length - 1], // Highest level
      amountNeeded: 0, // No further levels to achieve
    };

  }
  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <IFTContext.Provider
        value={{
          settings: settings,
          balance,
          dbuser,
          getuserDB,
          user_id,
          disconnectNow,
          formatAddress,
          toastError,
          toastSuccess,
          getudata,
          toastInfo,
          DBbalance,
          copyaddress,
          openLevel,
        }}
      >
        <App />
      </IFTContext.Provider>
    </>
  );
}

export default IFTcontext;
