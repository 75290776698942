import React, { useState } from 'react'
import Statstics from './Statstics'
import Staking from './Staking'
import Reward from './Reward'
import Witndwaral from './Witndwaral'
import ROIReward from './ROIReward'
import ReferralIncome from './ReferralIncome'
import AddKyc from './AddKyc'

export default function Navbar() {
    const [pageName, setpageName] = useState("Dashboard")
    return (
        <>
            <section className="pools_table pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="pools_table__part">
                            <div className="pools_table__title mb-5 mb-md-6 ">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">{pageName}</h2>
                                {/* <p className="roboto wow fadeInUp">Access DApps via the Web3 Wallet extension or the respective mobile app</p> */}
                            </div>

                            <div className="singletab" >
                                <ul className="tablinks d-flex align-items-center gap-5 gap-sm-10 gap-md-15 gap-lg-19 mb-6 mb-md-8 wow fadeInUp" style={{"overflow-x":"auto","scrollbar-width":"none"}}>
                                    <li className="nav-links clickable-active active" onClick={() => setpageName('Dashboard')}>
                                        <button className="tablink ">Dashboard</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('Staking')}>
                                        <button className="tablink " >Staking</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('Staking Income')}>
                                        <button className="tablink " >S Income</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('ROI of ROI')}>
                                        <button className="tablink " >ROI of ROI</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('Referral Income')}>
                                        <button className="tablink " >Referral Income</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('Withdrawal')}>
                                        <button className="tablink " >Withdrawal</button>
                                    </li>
                                    <li className="nav-links clickable-active" onClick={() => setpageName('KYC')}>
                                        <button className="tablink " >KYC</button>
                                    </li>
                                </ul>
                                <div className="tabcontents">
                                    <div className="tabitem active wow fadeInUp">
                                        <Statstics />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <Staking />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <Reward />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <ROIReward />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <ReferralIncome />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <Witndwaral />
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <AddKyc />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
