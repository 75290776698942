import React, { useContext, useEffect, useState } from "react";
import Pagination from "../Comman/Pagination";
import axios from "axios";
import { IFTContext } from "../../IFTContext";

export default function ROIReward() {
  const { user_id } = useContext(IFTContext);
  const [data, setdata] = useState([]);
  // const [isLoading, setisLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const getData = async () => {
    if (!user_id) {
      return;
    }
    // setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "rincome",
        submethod: "getbyid",
        u_id: user_id,
        // address: "default",
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize,
      })
      .then((res) => {
        // setisLoading(false);
        // console.log("res", res.data);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [user_id, pages, pageSize, currentPage]);
  return (
    <>
      <div className="pools_table__totalitem overflow-auto">
        <table>
          <tbody>
            <tr>
              <th>#</th>
              <th>From</th>
              <th>Staking</th>
              <th>Level</th>
              <th>Reward</th>
              <th>Reward Per</th>
              <th>Self Staking</th>
              <th>Team Business</th>
              <th>Flushed</th>
              <th>Datetime</th>
            </tr>
            {data.map((e, i) => {
              return (
                <tr>
                  <td>{e.id}</td>
                  <td>
                    {/* <a
                      className="py-1 px-2 bg1-color rounded-1 p1-color"
                      href={`${process.env.REACT_APP_EXPLORER}address/${e.from}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    > */}
                      {e.from}
                    {/* </a> */}
                  </td>
                  <td>$ {e.staking}</td>
                  <td>{e.level}</td>
                  <td>$ {e.income} </td>
                  <td> {e.income_per} %</td>
                  <td>$ {e.self_staking} </td>
                  <td>$ {e.team_business} </td>
                  <td>
                    {" "}
                    <span className="p3-color">
                      {" "}
                      {e.flushed ? "Flushed" : ""}
                    </span>
                  </td>
                  <td>{e.datetime}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pages={pages}
      />
    </>
  );
}
