import React, { useContext } from "react";
import { IFTContext } from "../../IFTContext";
import Stake from "./Stake";

export default function Statstics() {
  const {  copyaddress, settings, dbuser, user_id, openLevel } = useContext(IFTContext);

  return (
    <>
      <section className="earn_crypt pt-0 pb-120">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="col-12">
              <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                <h2>
                  Grow your portfolio with{" "}
                  <span className="p1-color">Infinity</span>
                </h2>
              </div>
            </div>
            <div className="col-xl-6">
              <Stake  />
            </div>
            <div className="col-xl-6">
              <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <h2 className="mb-3 text-center">Deposit Address</h2>
                <div className="d-flex align-items-center gap-3 justify-content-between flex-wrap mb-5 mb-md-6">
                  <div className="mb-3">
                    <div className="d-flex align-items-end gap-2">
                      <h6 className="fw_500 " onClick={() => copyaddress(dbuser ? dbuser.user_id : "")} title="Click to copy address">
                        User ID: {dbuser ? dbuser.user_id : ""}
                        <i class="ti ti-clipboard-copy fs-five px-1 p4-color "></i>
                      </h6>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex align-items-end gap-2">
                      <h1 className="fw_500">
                        ${" "}
                        {dbuser !== null
                          ? Number(dbuser.last_stake_reward).toFixed(2)
                          : "0"}
                      </h1>
                      <span className="mb-2">USDT</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-end gap-2">
                    <h1 className="fw_500">
                      $ {dbuser !== null ? dbuser.last_stake * 2 : "0"}
                    </h1>
                    <span className="mb-2">2x</span>
                  </div>
                  <div>
                    <span className="mb-2">2x reached</span>
                    <h1 className="fw_500 p1-color text-sm-end">
                      <span id="demo">
                        {dbuser !== null ? Number(dbuser.last_stake_3x_per).toFixed(1) : "0"}
                      </span>
                      %
                    </h1>
                  </div>
                </div>
                <div className="earn_crypto__rangeslider">
                <div className="range-style overflow-hidden position-relative w-100">
                  <input
                    type="range"
                    min={0}
                    max={100}
                    value={dbuser !== null ? dbuser.last_stake_3x_per : "0"}
                    className="slider w-100 rounded-5"
                  />
                </div>
                </div>
                

                <h2 className="mb-3 pt-4">Account Details</h2>
                <h2 className="mb-3"></h2>
                <div className="d-flex align-items-end gap-2 mb-8 mb-md-10">
                  <h6 className="fw_500 " onClick={() => copyaddress(dbuser.ref_id)} title="Click to copy address">
                    Referral ID :{dbuser ? dbuser.ref_id : ""}{" "}
                    <i class="ti ti-clipboard-copy fs-five px-1 p4-color "></i>
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/staking_amount.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          ${" "}
                          {dbuser !== null
                            ? Number(dbuser.t_staking).toFixed(2)
                            : "0"}
                        </h5>
                        <span>Staking Amount</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/today_roi.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          {" "}
                          {settings !== null
                            ? Number(settings.staking_per).toFixed(2)
                            : "0"}{" "}
                          %
                        </h5>
                        <span>Today ROI</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-9 mb-md-13">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/direct_members.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          {dbuser !== null ? dbuser.t_direct : "0"}
                        </h5>
                        <span>Direct Members</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/total_income.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">${dbuser !== null ? Number(dbuser.pen_principle).toFixed(2) : "0"}</h5>
                        <span>Pending Principal</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="cmn-btn py-3 px-5 px-md-6 d-block w-100"
                  onClick={() =>
                    copyaddress(`${process.env.REACT_APP_LINK}register?referral=${user_id}`)
                  }
                >
                  Copy Referral Link
                </button>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-12">
                  <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                    <h2 className="mb-3">Withdrawal</h2>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                      <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                          <img
                            src="assets/images/infinity_icon/total_withdrawal.png"
                            alt="Icon"
                          />
                          <div className="d-flex flex-column">
                            <h5 className="mb-1">
                              $ {dbuser !== null ? Number(dbuser.t_withdrawal).toFixed(2) : "0"}
                            </h5>
                            <span>Total Withdrawal</span>
                          </div>
                        </div>
                      </div>
                      <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                          <img
                            src="assets/images/infinity_icon/p_withdrwal.png"
                            alt="Icon"
                          />
                          <div className="d-flex flex-column">
                            <h5 className="mb-1">
                              {" "}
                              $ {dbuser !== null ? Number(dbuser.p_with).toFixed(2) : "0"}
                            </h5>
                            <span>P. Withdrawal</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-5">
                  <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                    <h2 className="mb-3">Team</h2>
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                      <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                          <img
                            src="assets/images/infinity_icon/team_members.png"
                            alt="Icon"
                          />
                          <div className="d-flex flex-column">
                            <h5 className="mb-1">
                              {dbuser !== null ? dbuser.team : "0"}
                            </h5>
                            <span>Team Members</span>
                          </div>
                        </div>
                      </div>
                      <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                        <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                          <img
                            src="assets/images/infinity_icon/team_business.png"
                            alt="Icon"
                          />
                          <div className="d-flex flex-column">
                            <h5 className="mb-1">
                              $ {dbuser !== null ? dbuser.team_business : "0"}
                            </h5>
                            <span>Team Business</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="earn_crypto__cardtwo bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                <h2 className="mb-3">Incomes</h2>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-5 mb-md-6">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/total_income.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          $ {dbuser !== null ? Number(dbuser.staking_rew + dbuser.level_rew + dbuser.ror_rew).toFixed(2) : "0"}
                        </h5>
                        <span>Total Income</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/roi.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          $ {dbuser !== null ? Number(dbuser.staking_rew).toFixed(2) : "0"}
                        </h5>
                        <span>ROI</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-9 mb-md-13">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/referral.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          $ {dbuser !== null ? Number(dbuser.level_rew).toFixed(2) : "0"}
                        </h5>
                        <span>Referral</span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/ror.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          $ {dbuser !== null ? Number(dbuser.ror_rew).toFixed(2) : "0"}
                        </h5>
                        <span title="ROI of ROI">R.O.R</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap flex-xl-nowrap gap-5 gap-md-6 mb-9 mb-md-13">
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/ror.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          {openLevel(dbuser !== null ? Number(dbuser.t_staking) : 0).level}
                        </h5>
                        <span title="Open Income Level">Open Level </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg1-color p-5 p-md-6 br2 rounded-3 w-100 ">
                    <div className="d-flex align-items-center flex-wrap gap-3 mb-4">
                      <img
                        src="assets/images/infinity_icon/ror.png"
                        alt="Icon"
                      />
                      <div className="d-flex flex-column">
                        <h5 className="mb-1">
                          $ {openLevel(dbuser !== null ? Number(dbuser.t_staking) : 0).amountNeeded}
                        </h5>
                        <span title="Staking Required for Next Level">Next Level Req.  </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
